import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>WHAT TO DO AFTER AN EXTRACTION</h2>
      <p>You need to look after yourself carefully after you have had a tooth taken out, as with any operation, to speed up healing and prevent infection, This advice is to help you know what to expect and do, as your mouth recovers.</p>
      <h3>General advice</h3>
      <p>For the first 24 hours, don't drink alcohol,  eat hot food or disturb the clot, which will have formed in the space left by the tooth, because this may cause the socket to start bleeding again. Don't smoke either, and avoid strenuous exercise for the rest of the day.</p>
      <p>Don't rinse your mouth for six hours after the extraction.</p>
      <p>After six hours, rinse gently with warm salty water to keep the socket clean and continue  to do this for up to a week after meals and before bed. Use half a teaspoonful of salt in a glass of comfortably warm water.</p>
      <p>Brush  your teeth normally with toothpaste to keep the whole mouth clean but take care in the region where the tooth was extracted.</p>
      <p>If you feel small pieces of bone working  their way out of the socket, don't worry- this is normal.</p>
      <p>Some swelling  or discomfort in the first two to three days is also normal.</p>
      <p>Take painkillers if you need them (as you would for a headache). Ask your dentist for advice if you are not sure which sort to take.</p>

      <p>If the bleeding does not stop:</p>
      <ul>
        <li>Your dentist may have given you a small supply of gauze in case this happens. If not, clean cotton handkerchiefs will do, but not paper tissues.</li>
        <li>Roll some small firm pads about one centimetre by three centimetres - a size that will fit over the socket.</li>
        <li>Keep sitting up and gently clear away any clots of blood around  the socket with the gauze  or handkerchief.</li>
        <li>Place a pad across  the socket from the tongue  side to the cheek and bite firmly on it for 10 to 15 minutes.</li>
        <li>Take off the pad and check whether  the bleeding has stopped. If it hasn't, use a fresh pad. If the socket is still bleeding, contact your dentist.</li>
      </ul>
      <p>Occasionally after extraction of a tooth, the blood clot in the socket can break down, leaving a painful empty hole in the gum. This is called "dry socket". If the socket becomes painful a day or two after the extraction, this is usually the reason. If it happens, you should go back to your dentist to have the wound cleaned and packed with a dressing; this will relieve the pain and reduce the risk of infection.</p>
      <p>If you follow these instructions, your mouth should heal normally, without becoming infected. But if anything in your mouth worries you, phone the practice for advice.</p>
    </LightboxLayout>
  )
}

export default Page